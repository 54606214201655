import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NavigationModule } from '@zipari/design-system';
import { WorkflowNavComponent } from './workflow-nav.component';

@NgModule({
    imports: [CommonModule, RouterModule, NavigationModule],
    declarations: [WorkflowNavComponent],
    exports: [WorkflowNavComponent],
})
export class WorkflowNavModule {}
