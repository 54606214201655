import { Component, Input, ElementRef, AfterViewInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { stringBuilder } from '@zipari/web-utils';
import { accordion } from '@zipari/design-system';
import { NavigationService } from '@zipari/shared-sbp-modules';
import { ConfigService } from '@zipari/shared-sbp-services';
import { applicationSources } from '@zipari/shared-sbp-models';

export class WorkflowNavConfig {
    logoRoute?: string = '/';
    exitEnrollmentRoute?: string = '/broker-portal/individual/clients';
    exitEnrollmentRouteTemplate?: string;
    exitEnrollmentText?: string = 'Exit Enrollment';
    exitEnrollmentIcon?: string = 'exit';

    constructor(options = {}) {
        Object.assign(this, options);
    }
}

@Component({
    selector: 'workflow-nav',
    templateUrl: './workflow-nav.component.html',
    styleUrls: ['./workflow-nav.component.scss'],
    animations: [accordion],
})
export class WorkflowNavComponent implements AfterViewInit {
    @Input() showSubNav: boolean;
    @Input() showAppID: boolean;
    @Input() isUtilityNavBarEnable: boolean;
    @Input() appID: string;
    @Input() enrollmentSubheader: any;
    @Input() exitEnrollmentRouteTemplateContext: { [routeTemplateProp: string]: any };

    constructor(
        private elRef: ElementRef,
        public navigationService: NavigationService,
        public router: Router,
        public configService: ConfigService
    ) {}

    _config: WorkflowNavConfig;

    public get config(): WorkflowNavConfig {
        return this._config;
    }

    @Input('config')
    public set config(config: WorkflowNavConfig) {
        this._config = new WorkflowNavConfig(config);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ('isUtilityNavBarEnable' in changes) {
            this.navigationService.setNavHidden(false);
        }
    }

    ngAfterViewInit() {
        const height: number = this.elRef.nativeElement.getBoundingClientRect().height;
        this.navigationService.setWorkflowNavHeight(height);
    }

    public getApplicationDetailUrl = (): string =>
        this.configService.app === applicationSources.zshop
            ? `/shopping/individual/applications/${this.appID}`
            : `/broker-portal/individual/applications/${this.appID}`;

    exitEnrollment(): void {
        if (this.appID) {
            this.config.exitEnrollmentRoute = this.getApplicationDetailUrl();
        }

        // use route template and context to build route, else use configured static route
        const exitRoute = this.config.exitEnrollmentRouteTemplate
            ? stringBuilder(this.config.exitEnrollmentRouteTemplate, this.exitEnrollmentRouteTemplateContext)
            : this.config.exitEnrollmentRoute;

        this.router.navigate([exitRoute]);
    }
}
